import React from 'react'
import { Link } from 'gatsby'

import Container from './container'
import * as styles from './footer.module.css'

const Footer = () => (
    <div className={styles.container}>
      <Container as="footer">
          <div className={styles.content}>
          <div>
          <h1 className={styles.title}>Let's chat</h1>
          <Link className='email' href="mailto:rob@swiftlab.co.uk">
            <a className={styles.subtitle}>rob@swiftlab.co.uk</a>
          </Link>
          <p className={styles.smallPrint}>©SwiftLab Ltd, Designed and coded by me, in Manchester, England</p>
          </div>
          <Link to="/" className={styles.logoLink}>
            <span className={styles.logo} />
          </Link>
        </div>
      </Container>
      {/* Built with <a href="https://contentful.com/">Contentful</a> and{' '}
      <a href="https://gatsbyjs.com">Gatsby</a> &middot;{' '} */}
      {/* <a href="https://github.com/contentful/starter-gatsby-blog">Source</a> */}
    </div>
)

export default Footer
