import React from 'react'
import { Link } from 'gatsby'

import * as styles from './navigation.module.css'

const Navigation = () => (
  <nav role="navigation" className={styles.container} aria-label="Main">
    <Link to="/" className={styles.logoLink}>
      <span className={styles.logo} />
      {/* <span className={styles.navigationItem}></span> */}
    </Link>
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        {/* <Link to="https://swiftlabltd.notion.site/Rob-Swift-aaed0757d20a46e992fabf525975696a" target="_blank" activeClassName="active">
          Resume
        </Link> */}
        <Link href="mailto:rob@swiftlab.co.uk" activeClassName="active">
          Contact
        </Link>
      </li>
      {/* <li className={styles.navigationItem}>
        <Link to="/blog/" activeClassName="active">
          Blog
        </Link>
      </li> */}
    </ul>
  </nav>
)

export default Navigation
